<template>
    <div>
        <ToolsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Air Duct Calculator</h1>

            <h2>Flexible Duct Friction Loss Calculator</h2>

            <p>1. Enter Friction Loss Per 100' of duct (inches of water), Duct Airflow (CFM), Duct Length and the number of bends.</p>
            <p>2. Read Duct Diameter (inches) and Duct Velocity (FPM).</p>

            <div class="cal_row row">
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Friction Loss<br>(inches water)</label><input type=text class='form-control' v-model.number="flexDuct1.frictionLoss">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Airflow<br>(CFM)</label><input type=text class='form-control' v-model.number="flexDuct1.ductAirflow">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Length<br>(feet)</label><input type=text class='form-control' v-model.number="flexDuct1.ductLength">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>90 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="flexDuct1.bends90">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>45 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="flexDuct1.bends45">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>180 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="flexDuct1.bends180">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Diameter<br>(inches)</label><input type=text class='form-control result_f' disabled="true" :value="flexDuct1FrictionLoss">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Velocity<br>(FPM)</label><input type=text class='form-control result_f' disabled="true" :value="flexDuct1Velocity">
                </div>
            </div>

            <p>1. Enter Round Duct Diameter (inches), Duct Airflow (CFM), Duct Length and the number of bends.</p>
            <p>2. Read Friction Loss Per 100' of duct (inches of water) and Duct Velocity (FPM).</p>

            <div class="cal_row row" id='cal2'>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Diameter<br>(water)</label><input type=text class='form-control' v-model.number="flexDuct2.ductDiameter">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Airflow<br>(CFM)</label><input type=text class='form-control' v-model.number="flexDuct2.ductAirflow">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Length<br>(feet)</label><input type=text class='form-control' v-model.number="flexDuct2.ductLength">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>90 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="flexDuct2.bends90">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>45 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="flexDuct2.bends45">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>180 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="flexDuct2.bends180">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Friction Loss<br>(inches water)</label><input type=text class='form-control result_f' disabled="true" :value="flexDuct2FrictionLoss">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Velocity<br>(FPM)</label><input type=text class='form-control result_f' disabled="true" :value="flexDuct2Velocity">
                </div>
            </div>

            <h2>Sheet Metal Duct Friction Loss Calculator</h2>
            <p>1. Enter Duct Airflow (CFM), Duct Velocity (FPM), Duct Length and the number of bends.</p>
            <p>2. Read Round Duct Diameter (inches) and Friction Loss Per 100' of duct (inches of water).</p>

            <div class="cal_row row" id='cal3'>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Velocity<br>(FPM)</label><input type=text class='form-control' v-model.number="sheetDuct1.ductVelocity">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Airflow<br>(CFM)</label><input type=text class='form-control' v-model.number="sheetDuct1.ductAirflow">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Length<br>(feet)</label><input type=text class='form-control' v-model.number="sheetDuct1.ductLength">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>90 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct1.bends90">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>45 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct1.bends45">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>180 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct1.bends180">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Diameter<br>(inches)</label><input type=text class='form-control result_f' disabled="true" :value="sheetDuct1Diameter">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Friction Loss<br>(inches water)</label><input type=text class='form-control result_f' disabled="true" :value="sheetDuct1FrictionLoss">
                </div>
            </div>

            <p>1. Enter Friction Loss Per 100' of duct (inches of water), Duct Airflow (CFM), Duct Length and the number of bends.</p>
            <p>2. Read Duct Diameter (inches) and Duct Velocity (FPM).</p>

            <div class="cal_row row" id='cal4'>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Friction Loss<br>(inches water)</label><input type=text class='form-control' v-model.number="sheetDuct2.frictionLoss">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Airflow<br>(CFM)</label><input type=text class='form-control' v-model.number="sheetDuct2.ductAirflow">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Length<br>(feet)</label><input type=text class='form-control' v-model.number="sheetDuct2.ductLength">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>90 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct2.bends90">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>45 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct2.bends45">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>180 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct2.bends180">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Diameter<br>(inches)</label><input type=text class='form-control result_f' disabled="true" :value="sheetDuct2Diameter">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Velocity<br>(FPM)</label><input type=text class='form-control result_f' disabled="true" :value="sheetDuct2Velocity">
                </div>
            </div>

            <p>1. Enter Round Duct Diameter (inches), Duct Airflow (CFM), Duct Length and the number of bends.</p>
            <p>2. Read Friction Loss Per 100' of duct (inches of water) and Duct Velocity (FPM).</p>

            <div class="cal_row row" id='cal5'>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Diameter<br>(inches)</label><input type=text class='form-control' v-model.number="sheetDuct3.ductDiameter">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Airflow<br>(CFM)</label><input type=text class='form-control' v-model.number="sheetDuct3.ductAirflow">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Length<br>(feet)</label><input type=text class='form-control' v-model.number="sheetDuct3.ductLength">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>90 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct3.bends90">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>45 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct3.bends45">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>180 Bends<br>(quantity)</label><input type=text class='form-control' v-model.number="sheetDuct3.bends180">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Friction Loss<br>(inches water)</label><input type=text class='form-control result_f' disabled="true" :value="sheetDuct3FrictionLoss">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Duct Velocity<br>(FPM)</label><input type=text class='form-control result_f' disabled="true" :value="sheetDuct3Velocity">
                </div>
            </div>

            <h2>Equivalent Rectangular Duct</h2>
            <p>1. Enter Round Duct Diameter.</p>
            <p>2. Enter the Desired Length of the Rectangular Duct.</p>

            <div class="cal_row row" id='cal6'>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Rd Duct Diameter<br>(inches)</label><input type=text class='form-control' v-model.number="rectDuct.roundDuctDiameter">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Rect. Duct Length<br>(inches)</label><input type=text class='form-control' v-model.number="rectDuct.rectLength">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Rect. Duct Width<br>(inches)</label><input type=text class='form-control result_f' disabled="true" :value="eqRectDuct">
                </div>
            </div>

            <h2>Equivalent Round Duct Diameter</h2>
            <p>1. Enter the Length of the Rectangular Duct.</p>
            <p>2. Enter the Width of the Rectangular Duct.</p>

            <div class="cal_row row" id='cal7'>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Rect. Duct Length<br>(inches)</label><input type=text class='form-control' v-model.number="roundDuct.rectLength">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Rect. Duct Width<br>(inches)</label><input type=text class='form-control' v-model.number="roundDuct.rectWidth">
                </div>
                <div class="col-md-2 col-xs-3 form-group">
                    <label>Rd. Duct Diameter<br>(inches)</label><input type=text class='form-control result_f' disabled="true" :value="eqRoundDuct">
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import ToolsNav from '@/components/content/ToolsNav.vue'
export default {
    name: "DuctCal",
    components: {
        ToolsNav,
    },
    data() {
        return {
            flexDuct1: {
                frictionLoss: 1.6,
                ductAirflow: 2000,
                ductLength: 100,
                bends90: 0,
                bends45: 0,
                bends180: 0,
            },
            flexDuct2: {
                ductDiameter: 12,
                ductAirflow: 2000,
                ductLength: 100,
                bends90: 0,
                bends45: 0,
                bends180: 0,
            },
            sheetDuct1: {
                ductVelocity: 2548,
                ductAirflow: 4000,
                ductLength: 100,
                bends90: 0,
                bends45: 0,
                bends180: 0,
            },
            sheetDuct2: {
                frictionLoss: 0.14,
                ductAirflow: 2000,
                ductLength: 100,
                bends90: 0,
                bends45: 0,
                bends180: 0,
            },
            sheetDuct3: {
                ductDiameter: 17,
                ductAirflow: 2000,
                ductLength: 100,
                bends90: 0,
                bends45: 0,
                bends180: 0,
            },
            rectDuct: {
                roundDuctDiameter: 16,
                rectLength: 14,
            },
            roundDuct: {
                rectLength: 14,
                rectWidth: 14,
            },
        }
    },
    computed: {
        flexDuct1FrictionLoss: function() {
            return Math.round(Math.pow((this.flexDuct1.ductAirflow/(3.12*(Math.pow( (this.flexDuct1.frictionLoss/(this.flexDuct1.ductLength+(this.flexDuct1.bends90*10)+(this.flexDuct1.bends45*5)+(this.flexDuct1.bends180*30))*100), 0.5)))),0.398)*10) / 10;
        },
        flexDuct1Velocity: function() {
            return Math.round(this.flexDuct1.ductAirflow/((Math.pow( (this.flexDuct1FrictionLoss/24), 2 ))*3.14));
        },
        flexDuct2FrictionLoss: function() {
            const ductVal = [
                false,
                false,
                false,
                0.00040000,
                0.00010000,
                0.00003000,
                0.00001000,
                0.00000600,
                0.00000300,
                0.00000200,
                0.00000100,
                false,
                0.00000040,
                false,
                0.00000020,
                false,
                0.00000010,
                false,
                0.00000005,
                false,
                0.000000003,
            ];
            const dv = ductVal[this.flexDuct2.ductDiameter];

            if (!dv) {
                return 'Invalid Duct';
            } else {
                const frictionLoss = ( dv*Math.pow(this.flexDuct2.ductAirflow,2) ) *((this.flexDuct2.ductLength+(this.flexDuct2.bends90*10)+(this.flexDuct2.bends45*5)+(this.flexDuct2.bends180*30))/100);
                return Math.round(frictionLoss * 100)/100;
            }
        },
        flexDuct2Velocity: function() {
            return Math.round(this.flexDuct2.ductAirflow/((Math.pow((this.flexDuct2.ductDiameter/24),2))*3.14));
        },
        sheetDuct1Diameter: function() {
            const ductDiameter = Math.pow((((this.sheetDuct1.ductAirflow/this.sheetDuct1.ductVelocity)*144)/3.14),0.5)*2;
            
            return Math.round(ductDiameter*10) /10;
        },
        sheetDuct1FrictionLoss: function() {
            const frictionLoss = Math.pow((this.sheetDuct1.ductAirflow / (3.12 * (Math.pow(this.sheetDuct1Diameter, 2.63)))), 2) * ((this.sheetDuct1.ductLength + (this.sheetDuct1.bends90 * 10) + (this.sheetDuct1.bends45 * 10) + (this.sheetDuct1.bends180 * 30)) / 100);

            return Math.round(frictionLoss * 100) / 100;
        },
        sheetDuct2Diameter: function() {
            const ductDiameter = Math.pow( (this.sheetDuct2.ductAirflow/(3.12* Math.pow((this.sheetDuct2.frictionLoss/(this.sheetDuct2.ductLength+(this.sheetDuct2.bends90*10)+(this.sheetDuct2.bends45*5)+(this.sheetDuct2.bends180*30))*100),0.5))),0.38);

            return Math.round(ductDiameter*10) /10;
        },
        sheetDuct2Velocity: function() {
            const ductVelocity = this.sheetDuct2.ductAirflow/(Math.pow((this.sheetDuct2Diameter/24),2)*3.14);

            return Math.round(ductVelocity);
        },
        sheetDuct3FrictionLoss: function() {
            const frictionLoss = Math.pow((this.sheetDuct3.ductAirflow/(3.12*(Math.pow(this.sheetDuct3.ductDiameter,2.63)))),2)*((this.sheetDuct3.ductLength+(this.sheetDuct3.bends90*10)+(this.sheetDuct3.bends45*10)+(this.sheetDuct3.bends180*30))/100);

            return Math.round(frictionLoss*100)/100;
        },
        sheetDuct3Velocity: function() {
            const ductVelocity = this.sheetDuct3.ductAirflow/(Math.pow((this.sheetDuct3.ductDiameter/24),2)*3.14);
            
            return Math.round(ductVelocity);  
        },
        eqRectDuct: function() {
            return Math.round((Math.PI* Math.pow((this.rectDuct.roundDuctDiameter/2),2))/this.rectDuct.rectLength);
        },
        eqRoundDuct: function() {
            return Math.round(Math.sqrt(this.roundDuct.rectWidth*this.roundDuct.rectLength/Math.PI)*2);
        },
    },
    created() {
        this.$appStore.setTitle(['Duct Calculator']);
    },
}
</script>

<style scoped>
.cal_row {
    padding-left: 5px;
}

.cal_row DIV {
    width: 115px;
    padding: 5px;
    font-size: 10px;
    text-align: center;
}

.cal_row input {
    text-align: right;
}

.result_f {
    color: #a94442;
}

</style>